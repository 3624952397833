<template>
  <!--运营统计-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="77px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="客户名称:">
                    <el-input
                      clearable
                      v-model="where.facilitator_name"
                      placeholder="请输入客户名称"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                      v-model="where.value1"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label=" ">
                    <div class="ele-form-actions">
                      <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                      </el-button>
                      <el-button @click="empty">清空</el-button>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="custom" style="margin-left: 20px">
                <!--<el-button class="button" type="primary">-->
                <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                <!--  <i class="el-icon-tianjia" />-->
                <!--  <span style="vertical-align: middle" @click="openEdit(null)">添加</span>-->
                <!--</el-button>-->
              </div>
            </div>
            <!--<div>-->
            <!--  <el-button>导出数据</el-button>-->
            <!--  <el-button @click="ListSetup()">列表设置</el-button>-->
            <!--</div>-->
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange">
              <!--自定义列显示-->
              <template v-slot:status= "{ scope }">
                <div v-if="scope.row.status === 0">
                  <span style="width: 8px;height: 8px;background: #BBB6AC;border-radius: 50%;display: inline-block;margin-right: 10px;"></span>
                  <span>已过期</span>
                </div>
                <div v-else>
                  <span style="width: 8px;height: 8px;background: #25EB61;border-radius: 50%;display: inline-block;margin-right: 10px;"></span>
                  <span>可使用</span>
                </div>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>

    </div>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi, set_statusApi,
} from '@/api/custom'


export default {
  // 组件生效
  components: {
    PackageSortElement,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "系统编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true,
          fixed: 'left'
        },
        {
          label: "发包方",
          prop: "id",
          isShow: true
        },
        {
          label: "订单总量",
          prop: "id",
          isShow: true
        },
        {
          label: "取消量",
          prop: "id",
          isShow: true
        },
        {
          label: "取消占比",
          prop: "id",
          isShow: true
        },
        {
          label: "有效订单",
          prop: "id",
          isShow: true
        },
        {
          label: "搭电数量",
          prop: "id",
          isShow: true
        },
        {
          label: "搭电占比",
          prop: "id",
          isShow: true
        },
        {
          label: "拖车数量",
          prop: "id",
          isShow: true
        },
        {
          label: "拖车占比",
          prop: "id",
          isShow: true
        },
        {
          label: "困境数量",
          prop: "id",
          isShow: true
        },
        {
          label: "困境占比",
          prop: "id",
          isShow: true
        },
        {
          label: "地库数量",
          prop: "id",
          isShow: true
        },
        {
          label: "地库占比",
          prop: "id",
          isShow: true
        },
        {
          label: "充气数量",
          prop: "id",
          isShow: true
        },
        {
          label: "充气占比",
          prop: "id",
          isShow: true
        },
        {
          label: "换胎数量",
          prop: "id",
          isShow: true
        },
        {
          label: "换胎占比",
          prop: "id",
          isShow: true
        },
        {
          label: "送油数量",
          prop: "id",
          isShow: true
        },
        {
          label: "送油占比",
          prop: "id",
          isShow: true
        },
        {
          label: "抢修数量",
          prop: "id",
          isShow: true
        },
        {
          label: "抢修占比",
          prop: "id",
          isShow: true
        },
        {
          label: "吊车数量",
          prop: "id",
          isShow: true
        },
        {
          label: "吊车占比",
          prop: "id",
          isShow: true
        },
        {
          label: "亏损订单量",
          prop: "id",
          isShow: true
        },
        {
          label: "亏损订单金额",
          prop: "id",
          isShow: true
        },
        {
          label: "订单量亏损率",
          prop: "id",
          isShow: true
        },
        {
          label: "订单来源结算",
          prop: "id",
          isShow: true
        },
        {
          label: "下游结算",
          prop: "id",
          isShow: true
        },
        {
          label: "渠道成本",
          prop: "id",
          isShow: true
        },
        {
          label: "营业执照",
          prop: "id",
          isShow: true
        },
        {
          label: "运营毛利额",
          prop: "id",
          isShow: true
        },
        {
          label: "运营毛利率",
          prop: "id",
          isShow: true
        },
        {
          label: "投诉单数",
          prop: "id",
          isShow: true
        },
        {
          label: "投诉率",
          prop: "id",
          isShow: true
        },
        {
          label: "催单单数",
          prop: "id",
          isShow: true
        },
        {
          label: "催单率",
          prop: "id",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,
    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getfacilitatorApi(this.where).then(async (res) => {
        this.total = res.data.count;
        // this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },


  }

}
</script>

<style lang="scss" scoped>

</style>
